import { createRouter, createWebHashHistory } from "vue-router";
import HomePage from "@/views/HomePage";
import AboutPage from "@/views/AboutPage";
import ContactPage from "@/views/ContactPage";
import ServicePage from "@/views/ServicePage";
import OfferPage from "@/views/OfferPage";
import ProcessPage from "@/views/ProcessPage";

const routes = [
  {
    name: "HomePage",
    path: "/",
    component: HomePage,
  },
  {
    name: "AboutPage",
    path: "/hakkimda",
    component: AboutPage,
  },
  {
    name: "ContactPage",
    path: "/iletisim",
    component: ContactPage,
  },
  {
    name: "ServicePage",
    path: "/hizmetler",
    component: ServicePage,
  },
  {
    name: "OfferPage",
    path: "/teklifform",
    component: OfferPage,
  },
  {
    name: "ProcessPage",
    path: "/islem",
    component: ProcessPage,
  },
];

const router = createRouter({
  routes,
  history: createWebHashHistory(),
});

export default router;
