<template>
  <link
    rel="stylesheet"
    href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.5.1/css/all.min.css"
    integrity="sha512-DTOQO9RWCH3ppGqcWaEA1BIZOC6xxalwEsw9c2QQeAIftl+Vegovlnee1c9QX4TctnWMn13TZye+giMm8e2LwA=="
    crossorigin="anonymous"
    referrerpolicy="no-referrer"
  />
  <NavbarMenu />
  <div class="container-md align-items-center d-flex my-5" style="">
    <div class="col">
      <div class="row justify-content-center">
        <div class="col-md-8 text-center">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d17591.582495440947!2d26.96081121152271!3d38.79163581702558!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14ba3159bb621089%3A0xac42403fc9cb4b67!2sMyVista%20Office%20II!5e0!3m2!1str!2str!4v1707146460521!5m2!1str!2str"
            width="100%"
            height="500"
            style="border: 0"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>
      <div class="row justify-content-center mt-4">
        <div class="col-md-8">
          <div class="row text-center">
            <div class="col-md-6">
              <div class="row my-4" style="min-height: 100px">
                <h6 style="color: #4b4b4b">Adres :</h6>
                <p>
                  Atatürk Mahallesi 356.sokak No:16 Daire:5 My Vista İş Hanı
                  İZMİR / Aliağa
                </p>
              </div>
              <div class="row my-4" style="min-height: 100px">
                <h6 style="color: #4b4b4b">Mail Adresi :</h6>
                <p>info@beselturizm.com</p>
              </div>
            </div>
            <div class="col-md-6">
              <div class="row my-4" style="min-height: 100px">
                <h6 style="color: #4b4b4b">İletişim Numaraları :</h6>
                <p>
                  Whatsapp Hattı: +90 532 171 05 42<br />
                  Telefon: +90 532 475 19 86
                </p>
              </div>
              <div class="row my-4" style="min-height: 100px">
                <h6 style="color: #4b4b4b">Sosyal Medya :</h6>
                <p
                  class="col-6 offset-3 my-2 d-flex justify-content-between"
                  style="font-size: 2rem"
                >
                  <a class="nav-link"
                    ><i class="fa-brands fa-linkedin hover"></i
                  ></a>
                  <a class="nav-link"
                    ><i class="fa-brands fa-x-twitter hover"></i
                  ></a>
                  <a class="nav-link"
                    ><i class="fa-brands fa-instagram hover"></i
                  ></a>
                  <a class="nav-link" href="https://wa.me/905321710542"
                    ><i class="fa-brands fa-whatsapp hover"></i
                  ></a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <FooterMenu />
</template>

<script>
import NavbarMenu from "@/components/NavbarMenu.vue";
import FooterMenu from "@/components/FooterMenu.vue";

export default {
  name: "ContactPage",
  components: {
    NavbarMenu,
    FooterMenu,
  },
};
</script>

<style scoped></style>
