<template>
  <div>
    <NavbarMenu />
    <div class="container my-5 col-md-5">
      <div class="card text-center">
        <div class="card-body">
          <h3 class="card-title">İşleminiz Başarılı</h3>
          <p class="card-text py-4 px-3">
            Teklifiniz gönderildi. Eksiksiz doldurduysanız, belirttiğiniz
            E-postanıza geri dönüş sağlanacaktır. Teşekkür Ederiz...
          </p>
          <router-link to="/" class="btn btn-success"
            >Anasayfaya geri dön</router-link
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NavbarMenu from "@/components/NavbarMenu.vue";

export default {
  name: "ProcessPages",
  components: {
    NavbarMenu,
  },
};
</script>

<style scoped>
.talep {
  border: 1px solid rgba(128, 128, 128, 0.3);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
}
</style>
