<template>
  <link
    rel="stylesheet"
    href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.5.1/css/all.min.css"
    integrity="sha512-DTOQO9RWCH3ppGqcWaEA1BIZOC6xxalwEsw9c2QQeAIftl+Vegovlnee1c9QX4TctnWMn13TZye+giMm8e2LwA=="
    crossorigin="anonymous"
    referrerpolicy="no-referrer"
  />
  <div class="sticky-top">
    <div class="bg-white p-3" style="height: ">
      <nav class="navbar container-md" data-bs-theme="dark">
        <div class="logo">
          <a class="navbar-brand" href="#"
            ><img
              src="@/assets/navbar-logo.png"
              width="200px"
              alt=""
              style="position: absolute; top: -45px"
          /></a>
        </div>
        <input type="checkbox" id="nav-toggle" class="nav-toggle" />
        <label for="nav-toggle" class="nav-toggle-label">
          <i class="fa-solid fa-bars"></i>
        </label>
        <ul class="nav-links">
          <li>
            <router-link
              class="nav-link"
              active-class="active"
              to="/"
              @click="scrollToTop"
              >Anasayfa</router-link
            >
          </li>
          <li>
            <router-link
              class="nav-link"
              active-class="active"
              to="/hakkimda"
              @click="scrollToTop"
              >Hakkımızda</router-link
            >
          </li>
          <li>
            <router-link
              class="nav-link"
              active-class="active"
              to="/hizmetler"
              @click="scrollToTop"
              >Hizmetlerimiz</router-link
            >
          </li>
          <li>
            <router-link
              class="nav-link"
              active-class="active"
              to="/teklifform"
              @click="scrollToTop"
              >Teklif al</router-link
            >
          </li>
          <li>
            <router-link
              class="nav-link"
              active-class="active"
              to="/iletisim"
              @click="scrollToTop"
              >İletişim</router-link
            >
          </li>
        </ul>
      </nav>
    </div>
    <div class="col-12 bg-body-tertiary text-center" style="font-size: 13px">
      <div class="text-light" style="background-color: #be1111">
        +90 532 171 05 42 / +90 532 475 19 86
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NavbarMenu",
  data() {},
  methods: {
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
  },
};
</script>

<style scoped>
/* CSS still remains the same */
</style>

<style scoped>
.nav-toggle {
  display: none;
}

.nav-toggle-label {
  display: none;
  padding: 10px 15px;
  border-radius: 5px;
  transition: 0.5s;
}

.nav-toggle-label:hover {
  cursor: pointer;
  background-color: rgb(187, 187, 187);
}

i {
  display: block;
}

.nav-links {
  display: flex;
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.nav-links li {
  margin-left: 1rem;
}

.nav-links li:first-child {
  margin-left: 0;
}

.nav-links a {
  text-decoration: none;
}

.active {
  border-bottom: 2px solid red;
}

/* Media Query for Mobile */
@media screen and (max-width: 768px) {
  .nav-toggle-label {
    display: block;
  }
  .nav-links {
    display: none;
    flex-direction: column;
    background-color: white;
    margin-top: 2rem;
    left: 0;
    width: 100%;
  }

  .nav-links a active {
    color: red;
  }

  .active {
    color: red;
    border: none;
  }
  .nav-links li {
    margin-left: 0;
    text-align: center;
    padding: 10px 0;
    border-bottom: 1px solid #0000002c;
  }

  .nav-toggle:checked + .nav-toggle-label + .nav-links {
    display: flex;
  }
}
</style>
