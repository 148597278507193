<template>
  <link
    rel="stylesheet"
    href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.5.1/css/all.min.css"
    integrity="sha512-DTOQO9RWCH3ppGqcWaEA1BIZOC6xxalwEsw9c2QQeAIftl+Vegovlnee1c9QX4TctnWMn13TZye+giMm8e2LwA=="
    crossorigin="anonymous"
    referrerpolicy="no-referrer"
  />
  <div>
    <label class="kvkkhover" @click="openModal" style="font-size: 14px"
      >Kişisel Verileri Koruma Kanunu (KVKK) aydınlatma metnini okudum,
      anladım.</label
    >

    <div class="modal" :class="{ show: showModal }" tabindex="-1" role="dialog">
      <div class="modal-dialog my-5" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">KVKK Metni Onayı</h5>
          </div>
          <div class="modal-body">
            <p>
              Veri sorumlusu sıfatıyla, tarafınızca bu sistemden bizimle
              paylaşacağınız kişisel verilerinizin, kaydedileceğini,
              depolanacağını, muhafaza edileceğini, yeniden düzenleneceğini,
              kanunen bu kişisel verileri talep etmeye yetkili olan kurumlar ile
              paylaşılacağını ve KVKK’nın öngördüğü şartlarda, yurtiçinde
              ve/veya yurtdışındaki; iştiraklerine / bağlı
              ortaklıklarına,hizmet/destek/danışmanlık aldığı/ işbirliği
              yaptığı/proje/program ortağı olduğu kamu ve/veya özel üçüncü
              taraflara, ilgili mevzuatları uyarınca tüm yasal ve idari
              mercilere aktarılabileceğini, devredileceğini,
              sınıflandırılabileceğini ve KVKK’da sayılan sair şekillerde
              işlenebileceğini bildiririz. Veri sahibi olarak taleplerinizi,
              www.beselturizm.com adresimizdeki veri sahibi başvuru formunu
              doldurarak ilettiğinizde en geç 30 gün içinde ücretsiz
              sonuçlandırılacaktır.
            </p>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-success" @click="closeModal">
              Devam Et
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showModal: false,
    };
  },
  methods: {
    openModal() {
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
    },
  },
};
</script>

<style scoped>
.modal {
  display: none;
  background: rgba(0, 0, 0, 0.6);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.modal.show {
  display: block;
}

.modal-content {
  position: relative;
  background-color: #fff;
  border: 1px solid #888;
  border-radius: 5px;
  padding: 20px;
}

.modal-title {
  margin-bottom: 20px;
}

.modal-body {
  margin-bottom: 20px;
  text-wrap: pretty;
}

.modal-footer {
  text-align: right;
}
</style>
