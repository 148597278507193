<template>
  <link
    rel="stylesheet"
    href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.5.1/css/all.min.css"
    integrity="sha512-DTOQO9RWCH3ppGqcWaEA1BIZOC6xxalwEsw9c2QQeAIftl+Vegovlnee1c9QX4TctnWMn13TZye+giMm8e2LwA=="
    crossorigin="anonymous"
    referrerpolicy="no-referrer"
  />
  <div
    class="col-12 text-light align-items-center justify-content-center d-flex"
    style="height: 300px; background-color: #f0f0f0"
  >
    <div class="text-center text-dark p-2" style="font-size: 1.5rem">
      "Beşel ailesi olarak siz değerli misafirlerimizin yükünü yük etmekten
      kurtarıyoruz."
    </div>
  </div>
  <div
    class="col-12 text-light align-items-center d-flex"
    style="height: 300px; background-color: #be1111"
  >
    <div class="text-center col-4" style="font-size: 14px">
      <h6 class="mb-4">Kısayol Linkler</h6>
      <p class="hover">
        <router-link class="nav-link" to="/" @click="scrollToTop"
          >Anasayfa</router-link
        >
      </p>
      <p class="hover">
        <router-link class="nav-link" to="/hakkimda" @click="scrollToTop"
          >Hakkımızda</router-link
        >
      </p>
      <p class="hover">
        <router-link class="nav-link" to="/hizmetler" @click="scrollToTop"
          >Hizmetlerimiz</router-link
        >
      </p>
      <p class="hover">
        <router-link class="nav-link" to="/iletisim" @click="scrollToTop"
          >İletişim</router-link
        >
      </p>
      <p class="hover">
        <router-link class="nav-link" to="/teklifform" @click="scrollToTop"
          >Teklif Alın</router-link
        >
      </p>
    </div>
    <div class="text-center col-4" style="font-size: 1rem">
      <p class="copyright">
        Copyright @ 2024 -<strong> Beşel Turizm</strong>. Tüm Hakları Saklıdır.
      </p>
    </div>
    <div class="text-center col-4" style="font-size: 14px">
      <h6 class="mb-4">Taşımacılık Hizmetlerimiz</h6>
      <p class="hover">
        <router-link class="nav-link" to="/hizmetler" @click="scrollToTop"
          >Personel Taşımacılığı</router-link
        >
      </p>
      <p class="hover">
        <router-link class="nav-link" to="/hizmetler" @click="scrollToTop"
          >Öğrenci Taşımacılığı</router-link
        >
      </p>
      <p class="hover">
        <router-link class="nav-link" to="/hizmetler" @click="scrollToTop"
          >Turizm Taşımacılığı</router-link
        >
      </p>
      <p class="hover">
        <router-link class="nav-link" to="/hizmetler" @click="scrollToTop"
          >Lojistik Taşımacılığı</router-link
        >
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "FooterMenu",
  methods: {
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
  },
};
</script>
