<template>
  <link
    rel="stylesheet"
    href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.5.1/css/all.min.css"
    integrity="sha512-DTOQO9RWCH3ppGqcWaEA1BIZOC6xxalwEsw9c2QQeAIftl+Vegovlnee1c9QX4TctnWMn13TZye+giMm8e2LwA=="
    crossorigin="anonymous"
    referrerpolicy="no-referrer"
  />
  <div>
    <NavbarMenu />
    <div
      class="container-md my-5 text-center d-flex justify-content-center py-5 text-dark"
      style="font-size: 18px"
    >
      <div class="col-md-8">
        <H5>TEKLİF FORMUNUZ</H5>
        <form @submit.prevent="submitForm" class="row g-3 m-3">
          <div class="col-md-6 p-3">
            <input
              type="text"
              class="form-control"
              v-model="name"
              placeholder="Ad"
              aria-label="First name"
              pattern="^[a-zA-ZğüşıöçĞÜŞİÖÇ\s]*$"
              required
            />
          </div>
          <div class="col-md-6 p-3">
            <input
              type="text"
              class="form-control"
              v-model="surname"
              placeholder="Soy Ad"
              aria-label="Last name"
              pattern="^[a-zA-ZğüşıöçĞÜŞİÖÇ\s]*$"
              required
            />
          </div>

          <div class="col-md-6 p-3">
            <input
              type="email"
              class="form-control"
              v-model="email"
              placeholder="E-Posta"
              id="inputEmail4"
              required
            />
          </div>
          <div class="col-md-6 p-3" style="text-align: left">
            <input
              type="tel"
              class="form-control"
              v-model="tel"
              @input="formatPhoneNumber"
              id="telform"
              placeholder="Telefon"
              required
            />

            <label
              class="form-label px-2"
              for="telform"
              style="font-size: 13px; position: absolute; color: #d30000"
              >Örnek: 0500 000 00 00</label
            >
          </div>

          <div class="col-md-4 p-3" style="text-align: left">
            <input
              type="text"
              class="form-control"
              v-model="hours"
              placeholder="Çalışma saatleri"
              @input="formatHours"
              pattern="\d{2}:\d{2}\s-\s\d{2}:\d{2}"
              required
            />
            <label
              class="form-label px-2"
              for="telform"
              style="font-size: 13px; position: absolute; color: #d30000"
              >Örnek: 14:00 - 16:00</label
            >
          </div>
          <div class="col-md-4 p-3">
            <input
              type="text"
              class="form-control"
              v-model="count"
              placeholder="Yolcu Sayısı"
              pattern="[0-9]+"
              required
            />
          </div>
          <div class="col-md-4 p-3">
            <input
              type="text"
              class="form-control"
              v-model="dest"
              placeholder="İstikamet"
              pattern="[a-zA-ZğüşıöçĞÜŞİÖÇ\s]*"
              required
            />
          </div>

          <div class="col-md-12 px-3" style="text-align: left">
            <label for="Textarea1" class="form-label">Açıklama</label>
            <textarea
              class="form-control"
              v-model="explain"
              placeholder="Detayları burada belirtin..."
              id="Textarea1"
              rows="3"
            ></textarea>
          </div>

          <div class="col-md-12 px-3" style="text-align: left">
            <div class="form-check">
              <input
                @click="openModal"
                class="form-check-input"
                type="checkbox"
                id="Check1"
                required
              />
              <ModalMenu ref="modal" />
            </div>
          </div>

          <div class="col-md-12 px-3 d-flex" style="text-align: left">
            <button type="submit" class="btn btn-primary">
              Teklif İçin Gönder
            </button>
            <div class="col" style="text-align: right; font-size: 1rem">
              <span class="col-md-6"
                >veya
                <a href="https://wa.me/905321710542"
                  >Whatsapp üzerinden Teklif Al</a
                ></span
              >
            </div>
          </div>
        </form>
      </div>
    </div>

    <FooterMenu />
  </div>
</template>

<script>
import NavbarMenu from "@/components/NavbarMenu.vue";
import FooterMenu from "@/components/FooterMenu.vue";
import ModalMenu from "@/components/ModalMenu.vue";
import emailjs from "emailjs-com";

export default {
  name: "OfferPage",
  components: {
    NavbarMenu,
    FooterMenu,
    ModalMenu,
  },
  data() {
    return {
      name: "",
      surname: "",
      email: "",
      tel: "",
      hours: "",
      count: "",
      dest: "",
      explain: "",
    };
  },
  methods: {
    formatPhoneNumber() {
      let phoneNumber = this.tel.replace(/\D/g, "");
      phoneNumber = phoneNumber.slice(0, 11);
      const spaceIndices = [4, 8, 11];
      for (let i = 0; i < spaceIndices.length; i++) {
        if (phoneNumber.length > spaceIndices[i]) {
          phoneNumber =
            phoneNumber.slice(0, spaceIndices[i]) +
            " " +
            phoneNumber.slice(spaceIndices[i]);
        }
      }
      this.tel = phoneNumber;
    },
    formatHours() {
      this.hours = this.hours.replace(/\D/g, "");
      if (this.hours.length > 8) {
        this.hours = this.hours.slice(0, 8);
      }
      if (/^\d{2}(:\d{2})?\s?(-\s?\d{2}(:\d{2})?)?$/.test(this.hours)) {
        return;
      }
      let formattedHours = "";
      for (let i = 0; i < this.hours.length; i++) {
        if (i === 2 || i === 6) {
          formattedHours += ":";
        }
        if (i === 4) {
          formattedHours += " - ";
        }
        formattedHours += this.hours[i];
      }
      this.hours = formattedHours;
    },
    openModal() {
      this.$refs.modal.openModal();
    },
    submitForm() {
      emailjs
        .send(
          "service_4efi8si",
          "template_z0rk3yi",
          {
            from_name: this.name,
            from_surname: this.surname,
            from_email: this.email,
            from_tel: this.tel,
            from_hours: this.hours,
            from_count: this.count,
            from_dest: this.dest,
            from_explain: this.explain,
          },
          "VFll9rnoSJXfLhQWo"
        )
        .then(
          (response) => {
            console.log(
              "E-posta başarıyla gönderildi!",
              response.status,
              response.text
            );
            this.$router.push("/islem");
          },
          (error) => {
            console.error("E-posta gönderirken bir hata oluştu:", error);
          }
        );
    },
  },
};
</script>

<style scoped></style>
