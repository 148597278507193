<template>
  <div>
    <NavbarMenu />
    <div class="container-md my-5">
      <div class="row text-center justify-content-center my-5">
        <div class="col-sm-12 col-md-5 text-center p-3 m-5 border cardhover">
          <div class="mb-3">
            <img
              src="@/assets/about-Personel.jpeg"
              class="img-fluid"
              alt="Resim"
            />
          </div>
          <strong>PERSONEL TAŞIMACILIĞI</strong>
          <br /><br />
          <p>
            Çağımızın en önemli sorunu zamandır Beşel turizm ailesi olarak
            zamanın değerini biliyor ve sunduğumuz tüm hizmetlerimizin sorunsuz,
            güvenilir, konforlu ve zamanında olmasına önem gösteriyoruz.
          </p>
        </div>
        <div class="col-sm-12 col-md-5 text-center p-3 m-5 border cardhover">
          <div class="mb-3">
            <img
              src="@/assets/about-Öğrenci.jpeg"
              class="img-fluid"
              alt="Resim"
            />
          </div>
          <strong>ÖĞRENCİ TAŞIMACILIĞI</strong>
          <br /><br />
          <p>
            Geleceğimizin teminatı olan çocuklarımızın güvenliğini her şeyden
            önde tutulmaktadır. Öğrenci taşımacılığı alanında yılların tecrübesi
            ve uzmanlığına sahip olan firmamız, velilerine Beşel Turizm
            güvencesini yıllardır sağlamaktadır.
          </p>
        </div>

        <div class="col-sm-12 col-md-5 text-center p-3 m-5 border cardhover">
          <div class="mb-3">
            <img
              src="@/assets/about-Turizm.jpeg"
              class="img-fluid"
              alt="Resim"
            />
          </div>
          <strong>TURİZM TAŞIMACILIĞI</strong>
          <br /><br />
          <p>
            Antalya ve İstanbul çıkışlı turlarımız, şehir içi şehir dışı
            transferlerimiz ve alanında uzman tecrübeli kaptanlarımız yeni model
            tüm donanım ve bakımlara sahip araçlarımız ile siz değerli
            misafirlerimizin güvenli, konforlu ve sorunsuz ulaşımınızı
            sağlayarak Yıllardır olduğu gibi hayalini kurduğunuz her yere Beşel
            turizm ile ulaşmanın konforunu sunuyoruz.
          </p>
        </div>
        <div class="col-sm-12 col-md-5 text-center p-3 m-5 border cardhover">
          <div class="mb-3">
            <img
              src="@/assets/about-Lojistik.jpeg"
              class="img-fluid"
              alt="Resim"
            />
          </div>
          <strong>LOJİSTİK TAŞIMACILIĞI</strong>
          <br /><br />
          <p>
            Sunduğumuz hizmetlerin demirbaşı olan karayolu yük taşımacılığı 1965
            yılında Anadolu'da zamanın imkanları ile başlayan yolculuğumuzu
            günümüzün teknolojisi ile buluşturup geniş filo ağımız, taşıma
            kapasitemiz ve sorunsuz ulaşım çözümlerimiz sayesinde Beşel lojistik
            ailesi olarak siz değerli misafirlerimizin yükünü yük etmekten
            kurtarıyoruz.
          </p>
        </div>
      </div>
    </div>
    <FooterMenu />
  </div>
</template>

<script>
import NavbarMenu from "@/components/NavbarMenu.vue";
import FooterMenu from "@/components/FooterMenu.vue";

export default {
  name: "ServicePage",
  components: {
    NavbarMenu,
    FooterMenu,
  },
};
</script>

<style scoped></style>
