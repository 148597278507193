<template>
  <NavbarMenu />

  <div class="container-md my-5">
    <div class="row align-items-center d-flex">
      <div class="col-sm-12 col-md-6 text-center">
        <div class="transparent">
          <h3 class="text-center">KISACA HAKKIMIZDA</h3>
          <br />
          <p class="wrap">
            1985 yılında profesyonel ulaşım hayatına başlayan firmamız, yeni bir
            açılımda sürekli yeni hedeflere emin adımlarla yürümek üzere
            faaliyetlerine devam etmektedir.
            <br /><br />Amacımız, İş anlayışımızı, hizmet sektöründeki
            çalışmalarımızı, personel, turizm ve karayolu yük taşımacılığı
            alanında uzman, gerçekçi ve istikbal vaad eden bir şitket ortaya
            çıkarmaktır. <br /><br />İş ortaklarımızın hassasiyet ve önemini
            bilerek firmamız ihtiyaç duyulan tüm ulaşım hizmetlerinde daha
            güvenilir daha konforlu daha sorunsuz bir ortama taşımaktır.
          </p>
        </div>
      </div>
      <div class="col-sm-12 col-md-6">
        <img src="@/assets/logobüyük.jpg" class="img-fluid" alt="Resim" />
      </div>
    </div>
  </div>

  <div class="container-md my-5">
    <div class="row align-items-center d-flex">
      <div class="col-sm-12 col-md-6 text-center">
        <div class="col-sm text-center">
          <div>
            <strong>Misyonumuz</strong>
            <hr />
            <p style="">
              Hizmet verdiğimiz her sektörde kendini sürekli yenileyerek müşteri
              memnuniyetini ve güvenliğini en üst düzeyde tutarak ulaşımda
              mükemmelliği sağlamaktır.
            </p>
          </div>
        </div>
        <div class="col-md">
          <div class="col-sm mt-5 text-center">
            <div>
              <strong>Vizyonumuz</strong>
              <hr />
              <p style="">
                Ulaşımda mükemmelliği sağlayıp, lider konumumuzu sürdürmek ve
                tüm ulaşım sektörünü uluslararası pazarlara taşımaktır.
              </p>
            </div>
          </div>
          <div class="col-md">
            <div class="col-sm mt-5 text-center">
              <div>
                <strong>Hedefimiz</strong>
                <hr />
                <p style="">
                  Hizmetlerimizi memnuniyetle verip marka değerimizi en üst
                  seviyeye çıkarmak hedefimizdir.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-12 col-md-6">
        <img
          src="@/assets/office.jpeg"
          class="img-fluid"
          alt="Resim"
          style="border-radius: 5px"
        />
      </div>
    </div>

    <div class="row align-items-center d-flex my-5">
      <div class="col-sm-12 col-md-6">
        <div class="transparent py-5">
          <h3 class="">Politikamız</h3>
          <br />
          <p class="wrap" style="text-align: justify">
            <strong>BEŞEL TURİZM</strong> ailesi olarak hizmet sektöründe
            faaliyet göstermenin bilinciyle müşteri memnuniyetini her zaman en
            üst seviyede tutmayı ve müşteri isteklerini karşılamada
            rakiplerinden önde olmayı kendine ilke edinmiştir. BEŞEL‘in
            hedeflerine ulaşmasındaki en önemli kaynağı çalışanlarıdır.
            Çalışanların mutluluğu ve katılımı BEŞEL TURİZM’i başarıya götürecek
            en büyük etkendir
          </p>
          <ul>
            <li class="my-2" style="text-align: justify">
              Personel taşımacılığı, iş ve personel sağlığı,çevre ile ilgili ile
              ilgili yasal mevzuatlara uyar
            </li>
            <li class="my-2" style="text-align: justify">
              Çevre etkileri ile ilgili kirliliği önler,
            </li>
            <li class="my-2" style="text-align: justify">
              Yönetim Sistemlerinin şartlarına uyarak sistemlerinin etkinliğini
              sürekli iyileştirmek için çalışır.
            </li>
            <li class="my-2" style="text-align: justify">
              Verilen eğitimlerle çalışanların yaptıkları işlerde bilgili
              olmalarını sağlar.
            </li>
            <li class="my-2" style="text-align: justify">
              Müşteri memnuniyeti ve güvenini sağlayarak,sürekli müşteri
              memnuniyeti için çalışır.
            </li>
            <li class="my-2" style="text-align: justify">
              Kalitede sürekliliğin sağlanabilmesi amacıyla şirket genelinde
              planlı ve sistemli bir organizasyon ile hedeflenen kalite
              seviyesine ulaşmak.
            </li>
          </ul>
          <p class="wrap" style="text-align: justify">
            Sistemlerimizin şartlarını sağlamak ve geliştirmek hepimizin
            görevidir. BEŞEL TURİZM bu görevi takım çalışmasıyla yerine getirir.
            Sistem anlayışımız hataları düzeltmekte etkin, önlemekte daha
            başarılı olmaktır.
          </p>
        </div>
      </div>
      <div class="col-sm-12 col-md-6 mt-5">
        <img
          src="@/assets/about-us.jpeg"
          class="img-fluid"
          alt="Resim"
          style="border-radius: 5px"
        />
      </div>
    </div>
  </div>

  <FooterMenu />
</template>

<script>
import NavbarMenu from "@/components/NavbarMenu.vue";
import FooterMenu from "@/components/FooterMenu.vue";

export default {
  name: "AboutPage",
  components: {
    NavbarMenu,
    FooterMenu,
  },
};
</script>
