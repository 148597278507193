<template>
  <link
    rel="stylesheet"
    href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.5.1/css/all.min.css"
    integrity="sha512-DTOQO9RWCH3ppGqcWaEA1BIZOC6xxalwEsw9c2QQeAIftl+Vegovlnee1c9QX4TctnWMn13TZye+giMm8e2LwA=="
    crossorigin="anonymous"
    referrerpolicy="no-referrer"
  />
  <NavbarMenu />
  <div class="bg-dark homeimg d-flex justify-content-center align-items-center">
    <div
      class="container-md text-dark"
      style="font-size: 18px; background-color: #00000057"
    >
      <div class="row text-light justify-content-around text-center p-3">
        <div class="col-md p-4 m-3" style="background-color: #000000bb">
          <h5>Teklif Alın</h5>
          <br />
          <p style="font-size: 1rem">
            Size sunduğumuz hizmetler için özel teklif alın.
          </p>
          <router-link class="" to="/teklifform">
            <button
              button
              type="button"
              class="btn text-light"
              style="background-color: #be1111"
            >
              Hemen Başvur
            </button>
          </router-link>
        </div>
        <div class="col-md my-3 p-4 m-3" style="background-color: #000000bb">
          <h5>
            <i class="fa-brands fa-whatsapp"></i>
            Whatsapp Üzerinden Teklif Alın
          </h5>
          <br />
          <p style="font-size: 1rem">
            Detaylar için bizlere çevrimiçi hattan ulaşın.
          </p>
          <a class="" href="https://wa.me/905321710542">
            <button button type="button" class="btn text-light bg-success">
              <i
                class="fa-brands fa-whatsapp bg-success"
                style="font-size: 1rem"
              ></i>
              Whatsapp'a Bağlan
            </button>
          </a>
        </div>
      </div>
    </div>
  </div>

  <div class="container-md py-5 text-dark" style="font-size: 18px">
    <div
      class="row my-5 py-5 justify-content-center"
      style="text-align: center"
    >
      <div class="col-md-8">
        <strong>Beşel Turizm,</strong>
        <p class="mt-2">
          Beşel turizm ailesi olarak, ulaşımda konusunda ihtiyaç duyduğunuz her
          an üzerinizdeki yükü hafifleterek siz değerli misafirlerimize ve iş
          ortaklarımıza çözüm odaklı hizmet sunmaktır.
        </p>
      </div>
    </div>

    <div class="row my-5 py-5 text-dark justify-content-around text-center">
      <h5 class="">HİZMETLERİMİZ</h5>
      <div class="col-md my-5">
        <img
          src="@/assets/P-taşımacılık.jpg"
          class="w-100 hover"
          alt=""
          style="border-radius: 10px"
        />
        <hr />
        <strong>Personel Taşımacılığı</strong>
        <p
          class="my-2"
          style="
            font-size: 14px;
            text-shadow: 0px 0px 0.5px rgb(56, 56, 56);
            text-align: justify;
          "
        >
          Çağımızın en önemli sorunu zamandır Beşel turizm ailesi olarak zamanın
          değerini biliyor ve sunduğumuz tüm hizmetlerimizin sorunsuz,
          güvenilir, konforlu ve zamanında olmasına önem gösteriyoruz.
        </p>
      </div>
      <div class="col-md my-5">
        <img
          src="@/assets/Ö-taşımacılık.jpg"
          class="w-100 hover radius"
          alt=""
          style="border-radius: 10px"
        />
        <hr />
        <strong>Öğrenci Taşımacılığı</strong>
        <p
          class="my-2"
          style="
            font-size: 14px;
            text-shadow: 0px 0px 0.5px rgb(56, 56, 56);
            text-align: justify;
          "
        >
          Geleceğimizin teminatı olan çocuklarımızın güvenliğini her şeyden önde
          tutulmaktadır. Öğrenci taşımacılığı alanında yılların tecrübesi ve
          uzmanlığına sahip olan firmamız, velilerine Beşel Turizm güvencesini
          yıllardır sağlamaktadır.
        </p>
      </div>
      <div class="col-md my-5">
        <img
          src="@/assets/T-taşımacılık.jpg"
          class="w-100 hover"
          alt=""
          style="border-radius: 10px"
        />
        <hr />
        <strong>Turizm Taşımacılığı</strong>
        <p
          class="my-2"
          style="
            font-size: 14px;
            text-shadow: 0px 0px 0.5px rgb(56, 56, 56);
            text-align: justify;
          "
        >
          Antalya ve İstanbul çıkışlı turlarımız, şehir içi şehir dışı
          transferlerimiz ve alanında uzman tecrübeli kaptanlarımız yeni model
          tüm donanım ve bakımlara sahip araçlarımız ile siz değerli
          misafirlerimizin güvenli, konforlu ve sorunsuz ulaşımınızı sağlayarak
          Yıllardır olduğu gibi hayalini kurduğunuz her yere Beşel turizm ile
          ulaşmanın konforunu sunuyoruz.
        </p>
      </div>
      <div class="col-md my-5">
        <img
          src="@/assets/L-taşımacılık.jpg"
          class="w-100 hover"
          alt=""
          style="border-radius: 10px"
        />
        <hr />
        <strong>Lojistik Taşımacılığı</strong>
        <p
          class="my-2"
          style="
            font-size: 14px;
            text-shadow: 0px 0px 0.5px rgb(56, 56, 56);
            text-align: justify;
          "
        >
          Sunduğumuz hizmetlerin demirbaşı olan karayolu yük taşımacılığı 1965
          yılında Anadolu'da zamanın imkanları ile başlayan yolculuğumuzu
          günümüzün teknolojisi ile buluşturup geniş filo ağımız, taşıma
          kapasitemiz ve sorunsuz ulaşım çözümlerimiz sayesinde Beşel lojistik
          ailesi olarak siz değerli misafirlerimizin yükünü yük etmekten
          kurtarıyoruz.
        </p>
      </div>
    </div>

    <div class="row py-5 text-dark justify-content-around text-center p-3">
      <div class="col-md-4">
        <h6 style="color: #4b4b4b">İletişim Numaraları :</h6>
        <br />
        <p>Whatsapp Hattı: +90 532 171 05 42</p>
        <p>Telefon: +90 532 475 19 86</p>
      </div>
      <div class="col-md-4">
        <h6 style="color: #4b4b4b">Sosyal Medya :</h6>
        <br />
        <p
          class="col-6 offset-3 my-2 d-flex justify-content-between"
          style="font-size: 2rem"
        >
          <a class="nav-link"><i class="fa-brands fa-linkedin hover"></i></a>
          <a class="nav-link"><i class="fa-brands fa-x-twitter hover"></i></a>
          <a class="nav-link"><i class="fa-brands fa-instagram hover"></i></a>
          <a class="nav-link" href="https://wa.me/905321710542"
            ><i class="fa-brands fa-whatsapp hover"></i
          ></a>
        </p>
      </div>
    </div>
  </div>

  <FooterMenu />
</template>

<script>
import NavbarMenu from "@/components/NavbarMenu.vue";
import FooterMenu from "@/components/FooterMenu.vue";

export default {
  name: "HomePage",
  components: {
    NavbarMenu,
    FooterMenu,
  },
};
</script>
